import { createUseStyles, useTheme } from 'react-jss';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ORDER_STATUS } from '../../constants';

const useStyles = createUseStyles({
  item: {
    position: 'relative',
    display: 'flex',
    borderRadius: 5,
    background: '#FFF',
    padding: [0, 0, 20],
  },
  itemIcon: {
    position: 'relative',
    width: 30,
    '& span': {
      display: 'flex',
      zIndex: 1,
      width: 14,
      height: 14,
      margin: '0 auto',
      background: ({ theme: { color } }) => color.grey,
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        fontSize: 16,
        color: 'white',
      },
    },
  },
  itemIconActive: {
    '& span': {
      background: ({ theme: { color } }) => color.green,
    },
  },
  itemIconCurrent: {
    '& span': {
      width: 30,
      height: 30,
    },
  },
  itemContent: {
    flex: 1,
    marginLeft: 20,
    lineHeight: 1.5,
    '& a': {
      color: 'black',
    },
    '& h6': {
      fontSize: 16,
    },
    '& *': {
      fontSize: 14,
    },
    '& ul': {
      margin: [10, 0, 0, 20],
    },
    '& ul li': {
      listStyleType: 'disc',
    },
  },
  bar: {
    width: 2,
    top: 0,
    left: 14,
    bottom: 0,
    position: 'absolute',
    background: ({ theme: { color } }) => color.grey,
    zIndex: 0,
  },
  barCurrent: {
    background: ({ theme: { color } }) => color.green,
  },
});

const TrackingStateItem = ({ step, children, active, current, currentMark, noBar }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const currentStep = step === current;
  return (
    <div className={styles.item}>
      {!noBar && <div className={`${styles.bar} ${currentMark ? styles.barCurrent : ''}`} />}
      <div className={`${styles.itemIcon} ${active ? styles.itemIconActive : ''} ${currentStep ? styles.itemIconCurrent : ''}`}>
        <span>
          {currentStep && <FontAwesomeIcon icon={faCheck} />}
        </span>
      </div>
      <div className={styles.itemContent}>
        <h6>{ORDER_STATUS[step]}</h6>
        {children && children}
      </div>
    </div>
  );
};

export default TrackingStateItem;
