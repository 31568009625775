import { createUseStyles } from 'react-jss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TrackingIndex from './components/tracking/TrackingIndex';
import TrackingHome from './components/tracking/TrackingHome';
import { useStyles } from './common/css/theme';
import { MEDIA } from './common/css/grid';

const useLayout = createUseStyles({
  head: {
    marginBottom: 30,
    [`${MEDIA.MAX_S_SCREEN}`]: {
      marginBottom: 15,
    },
    '& div': {
      width: 100,
      height: 100,
      margin: [0, 'auto'],
      backgroundImage: 'url(/mm.svg)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      [`${MEDIA.MAX_S_SCREEN}`]: {
        width: 80,
        height: 80,
      },
    },
  },
  foot: {
    textAlign: 'center',
    marginTop: 40,
    fontSize: 10,
    fontFamily: 'Mono',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  wrap: {
    maxWidth: 450,
    padding: 40,
    margin: [0, 'auto'],
    [`${MEDIA.MAX_S_SCREEN}`]: {
      padding: 20,
    },
  },
});

const App = () => {
  useStyles();
  const layout = useLayout();
  return (
    <div className={layout.wrap}>
      <div className={layout.head}>
        <div />
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TrackingHome />} />
          <Route path="/:orderId" element={<TrackingIndex />} />
          <Route path="*" element={<>404</>} />
        </Routes>
      </BrowserRouter>
      <div className={layout.foot}>
        Hecho por mi mercadito ®
      </div>
    </div>
  );
};

export default App;
