import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  '@global': {
    '*, *:before, *:after': {
      boxSizing: 'border-box',
    },
    'html, body': {
      height: '100%',
      background: '#FFF',
    },
    'html, body, #root': {
      margin: 0,
      padding: 0,
      fontSynthesis: 'none',
      textRendering: 'optimizeLegibility',
      WebkitFontSmoothing: 'antialiased',
    },
    'body, #root': {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video': {
      margin: 0,
      padding: 0,
      border: 0,
      fontSize: '100%',
      font: 'inherit',
      verticalAlign: 'baseline',
    },
    'article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section': {
      display: 'block',
    },
    'ol, ul': {
      listStyle: 'none',
    },
    'blockquote, q': {
      quotes: 'none',
    },
    'blockquote:before, blockquote:after, q:before, q:after': {
      content: 'none',
    },
    table: {
      borderCollapse: 'collapse',
      borderSpacing: 0,
    },
    img: {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
    },
    'picture img': {
      width: '100%',
    },
    'h1, h2, h3, h4, h5, h6, strong, b': {
      fontWeight: 'bold',
    },
    'em, i': {
      fontStyle: 'italic',
    },
    a: {
      textDecoration: 'none',
    },
    input: {
      outline: 'none',
      border: 'none',
      borderRadius: 0,
    },
    'input, textarea, button': {
      appearance: 'none',
    },
    'input, select, textarea, button': {
      fontFamily: 'inherit',
    },
    button: {
      position: 'relative',
      padding: 0,
      border: 'none',
      background: 'none',
      outline: 'none !important',
      cursor: 'pointer',
      fontSize: '100%',
    },
    '@font-face': [
      {
        fontFamily: 'Mono',
        fontWeight: 'regular',
        src: 'url(/fonts/mono/sfmono-regular-webfont.woff)',
        fallbacks: [
          { src: 'url(/fonts/mono/sfmono-regular-webfont.woff2) format(woff2)' },
        ],
      },
      {
        fontFamily: 'Mono',
        fontWeight: 'bold',
        src: 'url(/fonts/mono/sfmono-bold-webfont.woff)',
        fallbacks: [
          { src: 'url(/fonts/mono/sfmono-bold-webfont.woff2) format(woff2)' },
        ],
      },
      {
        fontFamily: 'Mono',
        fontWeight: '900',
        src: 'url(/fonts/mono/sfmono-heavy-webfont.woff)',
        fallbacks: [
          { src: 'url(/fonts/mono/sfmono-heavy-webfont.woff2) format(woff2)' },
        ],
      },
    ],
    body: {
      fontFamily: 'Helvetica, Arial, sans-serif',
      color: '#000000',
      fontSize: '100%',
    },
  },
});

export const theme = {
  color: {
    grey: '#F0F3F4',
    green: '#28B77A',
  },
};
