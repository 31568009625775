import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createUseStyles, useTheme } from 'react-jss';
import moment from 'moment';
import TrackingStateItem from './TrackingStateItem';
import Loading from '../../common/Loading';
import {
  CONSUMER_KEY,
  CONSUMER_SECRET,
  ORDER_STATUS,
  FAILED_ORDER,
} from '../../constants';
import { MEDIA } from '../../common/css/grid';

const useStyles = createUseStyles({
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h1': {
      fontSize: 32,
      fontFamily: 'Mono',
      textTransform: 'uppercase',
      [`${MEDIA.MAX_S_SCREEN}`]: {
        fontSize: 24,
      },
    },
    '& span': {
      display: 'inline-block',
      background: 'black',
      fontFamily: 'Mono',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      color: 'white',
      padding: [4, 10],
      fontSize: 12,
      marginTop: 2,
      borderRadius: 5,
    },
    '& ul': {
      textAlign: 'center',
      fontSize: 14,
      margin: [20, 0, 40],
      '& li': {
        marginBottom: 6,
      },
    },
  },
  container: {
    '& hr': {
      margin: [20, 0],
      border: 'none',
    },
  },
  error: {
    fontFamily: 'Mono',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 14,
    padding: [20, 0],
  },
  info: {
    border: '1px dashed black',
    background: '#FFF7F0',
    marginTop: 20,
    fontFamily: 'Mono',
    textTransform: 'uppercase',
    fontSize: 13,
    padding: 15,
    textAlign: 'center',
    '& a': {
      fontWeight: 'bold',
      color: 'black',
      textDecoration: 'underline',
    },
  },
});

const TrackingIndex = () => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const [error, setError] = useState(null);
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    // setIsLoaded(true);
    fetch(
      `https://www.mimercadito.cl/wp-json/wc/v3/orders/${orderId}?consumer_key=${CONSUMER_KEY}&consumer_secret=${CONSUMER_SECRET}`,
    )
      .then((res) => res.json())
      .then(
        (result) => {
          // setIsLoaded(false);
          if (result?.data?.status === 404) {
            setError(result);
          } else {
            setOrder(result);
          }
        },
        (error) => {
          // setIsLoaded(false);
          setError(error);
        },
      );
  }, []);

  if (error) return <div className={styles.error}>{error.message}</div>;
  if (!order) return <Loading />;
  if (FAILED_ORDER.includes(order?.status))
    return <div className={styles.error}>Orden no existe</div>;

  const orderPay = moment(order.date_created);
  const orderPay1Day = moment(order.date_created).add(1, 'hours');
  const orderPay2Day = moment(order.date_created).add(1, 'day');
  // const stepOne = moment().isSameOrBefore(orderPay1Day);
  const stepTwo = moment().isSameOrAfter(orderPay1Day);
  const stepThree = moment().isSameOrAfter(orderPay2Day);
  const stepFour = order.status === 'completed';

  let currentStatus;
  if (order.status === 'completed') {
    currentStatus = 4;
  } else if (stepTwo && !stepThree) {
    currentStatus = 2;
  } else if (stepThree) {
    currentStatus = 3;
  } else {
    currentStatus = 1;
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>Orden #{order.id}</h1>
        <span>{ORDER_STATUS[currentStatus]}</span>
        <ul>
          <li>{orderPay.format('DD/MM/YYYY, h:mm a')}</li>
        </ul>
      </div>
      <TrackingStateItem
        step={1}
        active
        currentMark={stepTwo}
        current={currentStatus}
      >
        {currentStatus === 1 && (
          <p>
            Este proceso puede hasta 1 día hábil.{' '}
            <em>(El plazo de entrega no se ve afectado por esta espera.)</em>
          </p>
        )}
      </TrackingStateItem>
      <TrackingStateItem
        step={2}
        active={stepTwo}
        currentMark={stepThree}
        current={currentStatus}
      />
      <TrackingStateItem
        step={3}
        active={stepThree}
        currentMark={stepFour}
        current={currentStatus}
      >
        {currentStatus === 3 && (
          <>
            <p>
              ¡Ya estamos trabajando en tu pedido{' '}
              <span role="img" aria-label="Llamas">
                🔥🔥
              </span>
              !
            </p>
            <ul>
              <li>
                Si necesitas confirmar los tiempos de entrega, puedes chequear
                tu orden ó el siguiente link:{' '}
                <a
                  href="https://www.mimercadito.cl/info/despachos/"
                  target="blank"
                >
                  <u>https://www.mimercadito.cl/info/despachos/</u>
                </a>
              </li>
            </ul>
          </>
        )}
      </TrackingStateItem>
      <TrackingStateItem
        step={4}
        active={stepFour}
        current={currentStatus}
        noBar
      />
      <div className={styles.info}>
        Para más información, revisa este{' '}
        <a
          href="https://www.mimercadito.cl/info/despachos/"
          rel="noreferrer"
          target="_blank"
        >
          link
        </a>{' '}
        ó escríbenos a{' '}
        <a href="mailto:info@mimercadito.cl">info@mimercadito.cl</a>
      </div>
    </div>
  );
};

export default TrackingIndex;
