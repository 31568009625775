import React from 'react';
import { createRoot } from 'react-dom';
import { ThemeProvider } from 'react-jss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { theme } from './common/css/theme';

// Crear la raíz del árbol de renderizado
const root = createRoot(document.getElementById('root'));

root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);

// Si deseas medir el rendimiento en tu aplicación, pasa una función
// para registrar resultados (por ejemplo: reportWebVitals(console.log))
// o envíalos a un punto de análisis. Aprende más: https://bit.ly/CRA-vitals
reportWebVitals();
