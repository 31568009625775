import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  '@keyframes loading': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '30vh',
    flexDirection: 'column',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    '& i': {
      display: 'block',
      width: 20,
      height: 20,
      borderRadius: '50%',
      border: '2px solid black',
      borderRightColor: 'transparent !important',
      animation: '$loading .8s ease infinite',
      marginBottom: ({ message }) => (message ? 20 : 0),
    },
  },
});

const Loading = ({ message, fullHeight, inverted }) => {
  const styles = useStyles({ fullHeight, message, inverted });
  return (
    <div className={styles.loading}>
      <i />
      {message && message}
    </div>
  );
};

export default Loading;
