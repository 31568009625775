export const CONSUMER_KEY = 'ck_71fb39445b137e5930ef4ccc20ee459ddea0a401';
export const CONSUMER_SECRET = 'cs_e40ca4c1d1b345b84e7dd84b71ddcc06c01c4a08';

export const ORDER_STATUS = {
  1: 'Esperando confirmación de pago',
  2: 'Orden confirmada y pagada',
  3: 'Preparando productos',
  4: 'Orden completada y despachada',
};

export const FAILED_ORDER = ['failed', 'cancelled'];
