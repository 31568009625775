import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  form: {
    '& input': {
      border: '2px solid black',
      borderRadius: 5,
      height: 60,
      width: '100%',
      padding: [0, 20],
      textAlign: 'center',
      fontSize: 22,
      fontWeight: 'bold',
      fontFamily: 'Mono',
      textTransform: 'uppercase',
    },
    '& label': {
      display: 'block',
      textAlign: 'center',
      lineHeight: 1.2,
      marginBottom: 10,
    },
    '& button': {
      display: 'block',
      height: 50,
      background: 'black',
      color: 'white',
      width: '100%',
      fontSize: 16,
      marginTop: 10,
      borderRadius: 5,
      fontWeight: 'bold',
      fontFamily: 'Mono',
      textTransform: 'uppercase',
    },
  },
  disabled: {
    opacity: 0.3,
    cursor: 'default',
  },
});

const TrackingHome = () => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const [orderId, setOrderId] = useState(null);
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    navigate(`/${orderId}`);
  };

  return (
    <form onSubmit={onSubmit} className={styles.form}>
      <label>Ingresa tu número de orden</label>
      <input
        required
        type="text"
        placeholder="Ej: 0000"
        onChange={(e) => {
          setOrderId(e.target.value);
        }}
      />
      <button
        type="submit"
        disabled={!orderId}
        className={!orderId ? styles.disabled : ''}
      >Ingresar
      </button>
    </form>
  );
};

export default TrackingHome;
