export const MEDIA = {
  BREAK_SIDE: '@media screen and (max-width: 700px)',
  BREAK_MODAL: '@media screen and (max-width: 500px)',
  MAX_XL_SCREEN: '@media screen and (max-width: 1400px)',
  MAX_LM_SCREEN: '@media screen and (max-width: 1200px)',
  MAX_L_SCREEN: '@media screen and (max-width: 1000px)',
  MAX_M_SCREEN: '@media screen and (max-width: 700px)',
  MAX_S_SCREEN: '@media screen and (max-width: 500px)',
  MAX_XS_SCREEN: '@media screen and (max-width: 375px)',
  MIN_L_SCREEN: '@media screen and (min-width: 1001px)',
};
